.sf-dataview-modal-bod {
    width: 100%;
    height: 100%;
}
.sf-dataview-modal {
    width: 100%;
    height: 100%;
}
.sf-dataview-modaldlg {
    width: 70%;
    max-width: none!important;
    height: 80%;
    max-height: none!important;
}
.sf-dataview-modal-textdiv {
    display:inline-block;
    border: solid 1px white;
    min-height:10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}