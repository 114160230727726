.sf-app {
  /* text-align: center; */
  /* background-image: url("./back1.jpg"); */
  /* background-image: "./back1.jpg"; */
}
.sf-app-container-col {
  display: contents;
}
.sf-app-container {
  position: relative;
  min-height: 100vh; /* must be relative to footer height */
}
.sf-app-content-wrap {
  padding-bottom: 2rem; /* must be relative to footer height */
}
.sf-app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height:2rem; /*footer height*/;
}
.sf-app-blurbcontainer {
  background-color: #2C3531;
  color: rgba(255,255,255,.5);
  font-size: 0.8rem;
  font-style: italic;
  padding-top: 0px;
  padding-bottom: 0.3rem;
  padding-left: 2rem;
  padding-right: 0px;
  margin-top: -5px;
}
.sf-app-blurblink {
  background-color: #2C3531;
  color: rgba(255,255,255,.5);
  font-size: 0.8rem;
  font-style: italic;
  margin-top: -5px;
  padding-left: 0.3rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}