.sf-navbar {
    /* background-color: #20232a; */
    /* background-color: #222831; */
    background-color: #2C3531;
    color: white;
}
.sf-navbar-brand {
    color: white;
    margin-top: -10px;
}
.sf-navbar-brand img {
    height: 3.5rem;
}
.sf-navbar-createlink {

}
/* responsive, form small screens, hide create button on navbar */
@media (max-width: 800px) {
    .sf-navbar-create {
        display: none;
    }
}