.sf-app-userguide {
    background-color: #2C3531;
    /* color: rgba(255,255,255,.5); */
    color: white;
    font-size: 0.8rem;
    font-style: normal;
    padding-top: 0px;
    padding-bottom: 0.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: -5px;
  }
  .sf-app-userguide-jumbo {
    background-color: #2C3531;
    /* color: rgba(255,255,255,.5); */
    color: white;
    padding: 1rem 1rem;
  }
  .sf-app-userguide-commands{
    margin-right: 1rem;
  }
  .sf-app-userguide-closex {
    background-color: #2C3531;
    font-weight: bold;
    font-size: medium;
    position: absolute !important;
    right: 1rem;
    display: inline;
    color: white;
    padding: 15;
    border: none;
  }