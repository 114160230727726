.sf-item-card {
    /* background: #20232a; #333; previous best, dark grey */
    /* background: #30475e; */
    background-color: #2C3531;
    width: 25rem;
    margin: 0.5rem; 
    /* box-shadow: 5px 5px 5px lightgrey; */
    box-shadow: 0 .25rem .25rem rgba(0,0,0,0.2), 0 0 1rem rgba(0,0,0,0.2);
    color: #aaa;
    /* animation: sampleanimation 1s infinite;  */
    }
.sf-item-card-title {
    color: white;
    margin-top: 0;
    font-weight: 800;
    letter-spacing: 0.01em;
  }
.sf-item-card-body{
    padding-bottom: 0.7rem!important;
    /* color: #aaa; */
    color: white;
}
.sf-item-card-subtitle {
    color: white;
}
.sf-item-card-link {
    font-size: 0.7rem;
    padding-left: 0;
    padding-right: 0.8rem;
}
.sf-item-card-link-disabled {
    font-size: 0.7rem;
    cursor: none;
    opacity: 0.5;
    text-decoration: none;
}
.sf-item-datatexta {
    width: 100%;
    height: 245px;
    margin: 0;
}
.sf-item-title-container {
    width: 100%;
    display: flex;
}
.sf-item-title-container-text {
    width: 80%;
}
.sf-item-title-container-btnspace {
    width: 20%;
    min-width: 50px;
    text-align: right;
}
.sf-item-action-animation {
    animation: pulseanimation 0.2s forwards;
}
@keyframes pulseanimation {
    0% {
        left: -5px;
    }
    20% {
        left: -10px;
    }
    40% {
        left: -5px;
    }
    60% {
        left: +5px;
    }
    80% {
        left: +10px;
    }
    100% {
        left: 0px;
    }
  }