.sf-item-modal-bod {
    background-color: #1d2124;
    /* background-color: #171515; */
    color: white;
}
.sf-item-modal-title {
    /* background-color: #171515; */
    background-color: #1d2124;
    color: white;
}
.sf-item-modal-foot {
    /* background-color: #171515; */
    background-color: #1d2124;
    color: white;
}
.modal-content {
    /* background-color: #171515; */
    background-color: #1d2124;
    color: white;
}
